import React, { useState } from 'react'

import logo from '../assets/images/adf_logo-text.png'
import logoLarge from '../assets/images/adf_logo-large.png'
import info from '../assets/images/info_graphic_1.png'
import {auth} from "../firebase"
import { Link, Navigate, unstable_HistoryRouter, useNavigate } from 'react-router-dom'
import Swal from "sweetalert2";

function Reset() {
    const [email, setemail] = useState("");

    const [password, setpassword] = useState("");
    const navigate = useNavigate();
    const login = (e) => {
      e.preventDefault();
      auth
        .signInWithEmailAndPassword(email, password)
        .then((auth) => {
          navigate("/")      
        })
        .catch((error) => alert(error.message));
    };

    const reset=()=>{
    
        if(email==""){     
            Swal.fire({
              title: `Please, type your email`,
              confirmButtonColor: "#7F0707",
              confirmButtonText: "Ok",
              icon:"error"
            })
        }else{
    
          auth.sendPasswordResetEmail(email).then(()=>{            
            Swal.fire({
              title: `Password reset link sent to your email ${email} `,
              confirmButtonColor: "#7F0707",
              confirmButtonText: "Ok",
              icon:"success"
            })
        }).catch((error)=>{
            Swal.fire({
                title: `The email you entered is incorrect.`,
                confirmButtonColor: "#7F0707",
                confirmButtonText: "Ok",
                icon:"error"
              })
     
        })
          
        }
        
    }



  return (
    <div className='login light'>
    <div className='login__left d-none d-lg-block'>
        <img src={logo} className='login__left__logo'></img>
        <div className='login__left__bottom'>
        <img src={info} className='login__left__img d-none d-lg-block'></img>
        <div className='login__left__title'>ADF-GPT</div>
        <div className="d-flex justify-content-center text-capitalize text-light">
            Powerd By indepenedt news & media p.l.c
          </div>
        </div>
        


    </div>
    <div className='login__right'>
        <img src={logoLarge} className='login__right__logo d-lg-none'></img>
        <div className='login__right__title fw-bold'> Log In to ADF-GPT</div>
       
        <form>
            <div className='login__right__single d-flex flex-cloumn'>
            <label htmlFor="email" className='fw-bold'>Email</label>
            <input name="email" id="email" type="email" onChange={e=>setemail(e.target.value)}></input>

            </div>


            
           
            <div className='login__right__single'>
            <input name="reset" id="reset" type="button" value="Reset Password" onClick={e=>reset()}></input>

            </div>
            <Link to="/" className='text-decoration-none'><div className='login__right__single'>
                <input name="login" id="login" type="button" value="Back To Log In" ></input>

                </div></Link>
                
           
            
            
            
            
            
            


        </form>
    </div>




</div>
  )
}

export default Reset