import React, { useState } from 'react'

import logo from '../assets/images/adf_logo-text.png'
import logoLarge from '../assets/images/adf_logo-large.png'
import info from '../assets/images/info_graphic_1.png'
import {auth} from "../firebase"
import { Link, Navigate, unstable_HistoryRouter, useNavigate } from 'react-router-dom'
import Swal from "sweetalert2";

function LogIn() {
  const [email, setemail] = useState("");

  const [password, setpassword] = useState("");
  const navigate = useNavigate();
  const login = (e) => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
        navigate("/")      
      })
      .catch((error) => 
      Swal.fire({
        title: `The email or password you entered is incorrect.`,
        confirmButtonColor: "#7F0707",
        confirmButtonText: "Ok",
        icon:"error"
      }))
  };
 
  
  return (
    <div className='login light'>
        <div className='login__left d-none d-lg-block'>
            <img src={logo} className='login__left__logo'></img>
            <div className='login__left__bottom'>
            <img src={info} className='login__left__img d-none d-lg-block'></img>
            <div className='login__left__title'>ADF-GPT</div>
            <div className="d-flex justify-content-center text-capitalize text-light">
                Powerd By indepenedt news & media p.l.c
              </div>
            </div>
            


        </div>
        <div className='login__right'>
            <img src={logoLarge} className='login__right__logo d-lg-none'></img>
            <div className='login__right__title fw-bold'> Log In to ADF-GPT</div>
           
            <form onSubmit={e=>login(e)}>
                <div className='login__right__single d-flex flex-cloumn'>
                <label htmlFor="email" className='fw-bold'>Email</label>
                <input name="email" id="email" type="email" onChange={e=>setemail(e.target.value)} required></input>

                </div>


                <div className='login__right__single'>
                <label htmlFor="password" className='fw-bold'>Password</label>
                <input name="password" id="password" type="password" onChange={e=>setpassword(e.target.value)} required></input>

                </div>
                <div className='login__right__single'>
                <input name="login" id="login" type="submit" value="Log In" ></input>

                </div>
                
                  <Link to="/reset" className='text-decoration-none'>
                  <div className='login__right__single'>
                  <input name="reset" id="reset" type="button" value="Forgot Password ?" ></input>
                  
                </div>
                  </Link>
                

               
                
                
                
                
                
                


            </form>
        </div>




    </div>
  )
}

export default LogIn