import React, { useEffect, useState } from "react";
import { AiFillFolder } from "react-icons/ai";
import { FaCubes } from "react-icons/fa";
import { FaRandom } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";

import { BiLogOut } from "react-icons/bi";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import logo from "../assets/images/addis_fortune_logo.png";
import { BsCheckLg, BsFillMoonFill } from "react-icons/bs";
import { GiBroom } from "react-icons/gi";
import { IoMdSend } from "react-icons/io";
import { SiAudiomack } from "react-icons/si";
import { BsFillSunFill } from "react-icons/bs";
import { IoArrowUndoSharp } from "react-icons/io5";
import adfLogo from "../assets/images/addis_fortune_logo.png";
import gptLogo from "../assets/images/gptLogo.svg";
import gptLogoRed from "../assets/images/gptLogored.svg";
import saveIcon from "../assets/images/saveIcon.png";
import copyIcon from "../assets/images/copy.png";
import saveIconWhite from "../assets/images/saveIconwhite.png";
import copyIconWhite from "../assets/images/copyIconWhite.png";
import deleteIcon from "../assets/images/delete icon.png";

import "loaders.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  addDoc,
  collection,
  deleteDoc,
  doc
} from "firebase/firestore";
import { auth, db } from "../firebase";
import Swal from "sweetalert2";
import { signOut } from "firebase/auth";
import { Navigate } from "react-router-dom";
import { useStateValue } from "./StateProvider/StateProvider";
import axios from "axios";
// import multer from "multer";

function Home() {
  const [model, setmodel] = useState("gpt-3.5-turbo");
  const [modelName, setmodelName] = useState("gpt-3.5-turbo");
  const [temprature, settemprature] = useState("0.5");
  const [lightmode, setlightmode] = useState("");
  const [question, setquestion] = useState("");
  const [saveTitle, setsaveTitle] = useState("");
  const [saveTitlenew, setsaveTitlenew] = useState("");
  const [saveAnswer, setsaveAnswer] = useState("");
  const [chat, setchat] = useState([]);
  const [savedChat, setsavedChat] = useState([]);
  const [deleteID, setdeleteID] = useState("");
  const [modelList, setmodelList] = useState("");
  const [endPoint, setendPoint] = useState(
    "https://api.openai.com/v1/chat/completions"
  );
  const [waiting, setwaiting] = useState("");
  const [{user}] = useStateValue();
  const [file, setFile] = useState(null);
 

  // light mode change
  useEffect(() => {
    let darkText = document.querySelector(".darkText");
    let lightModeIcon = document.querySelector(".lightModeIcon");
    setlightmode(darkText.innerText);
  }, []);
  let darkText = document.querySelector(".darkText");
  const lightMode = () => {
    let body = document.querySelector(".body");
    let lightIcon = document.querySelector(".lightIcon");
    let darkIcon = document.querySelector(".darkIcon");
    let saveIconLight = document.querySelector(".saveIconLight");
    let saveIconDark = document.querySelector(".saveIconDark");
    let copyIconLight = document.querySelector(".copyIconLight");
    let copyIconDark = document.querySelector(".copyIconDark");

    darkIcon.classList.toggle("d-none");
    lightIcon.classList.toggle("d-none");
    saveIconLight && saveIconLight.classList.toggle("d-none");
    saveIconDark && saveIconDark.classList.toggle("d-none");
    copyIconLight && copyIconLight.classList.toggle("d-none");
    copyIconDark && copyIconDark.classList.toggle("d-none");

    if (darkText.innerText == "Dark") {
      darkText.innerText = "Light";
    } else {
      darkText.innerText = "Dark";
    }
    body.classList.toggle("dark");
  };

 

  // auto grow question input
  var textarea = document.querySelector(".body__bottom__input__text");
  let questionbox = document.getElementById("question");
  questionbox &&
    questionbox.addEventListener("input", () => {
      textarea.style.height = "auto";
      questionbox.style.height = questionbox.scrollHeight + "px";
    });

// model list fetch from server
  useEffect(() => {
    fetch(process.env.REACT_APP_FETCH_MODELS, {})
      .then((res) => res.json())
      .then((data) => {
        setmodelList(data);
      });
  }, []);

   // temprature value updater function
   const tempratureVal = (event, newValue) => {
    settemprature(newValue);
  };

   // make enter key submitter from text area
   const handleKeyDown = (e) => {
    const input = document.getElementById("question");
    if (e.key == "Enter") {
      handleSubmit(e);
      console.log(input && input.value);
    }
  };
  
// validate and put audio file to file state
  const handleFileInputChange = (event) => { 
    const fileType=event.target.files[0].type

   
    if(event.target.files[0].size/1024/1024>25){
    
      if(fileType=="audio/mpeg" || fileType=="audio/mp3" || fileType=="audio/wav" || fileType=="audio/m4a" || fileType=="audio/webm"){
        Swal.fire({
          title: `Your file exceeds the maximum size limit of 25MB.`,
          confirmButtonColor: "#7F0707",
          confirmButtonText: "Ok",
          icon:"error"
        })
        event.target.value=""
      }  else{ Swal.fire({
        title: `Invalid File, try Uplading audio file with mpeg, mp3, m4a or webm file format and maximum size of 25MB`,
        confirmButtonColor: "#7F0707",
        confirmButtonText: "Ok",
        icon:"error"
      })
      event.target.value=""}

      
    }else if(fileType=="audio/mpeg" || fileType=="audio/mp3" || fileType=="audio/wav" || fileType=="audio/m4a" || fileType=="audio/webm"){
      setFile(event.target.files[0]);
      event.target.value=""

    }
    
    else{ Swal.fire({
      title: `Invalid File, try Uplading audio file with mpeg, mp3, m4a or webm file format`,
      confirmButtonColor: "#7F0707",
      confirmButtonText: "Ok",
      icon:"error"
    });}
    event.target.value=""
    
    
  };
// trascribe audio function
  const transcribe = async (event) => {
    event.preventDefault();
    if(!file){
      Swal.fire({
        title: `Please, Choose your audio file first`,
        confirmButtonColor: "#7F0707",
        confirmButtonText: "Ok",
        icon:"error"
      })
    }else{
      const formData = new FormData();
    formData.append("file", file);
    formData.append("userID", user.uid);

    try{
      setwaiting("waiting");
    await axios.post("http://localhost:3600/whisper", formData)
  
    .then(res => {
      setwaiting("");
      setchat([
        ...chat,
        { question: file.name, answer: res.data.text },
      ]);
      })
      setwaiting("");
      setFile(null)

    }catch (error) {
      Swal.fire({
        title: `Sorry, there's an issue with the server. Please try again later.`,
        confirmButtonColor: "#7F0707",
        confirmButtonText: "Ok",
        
      })
      setwaiting("");
      setFile(null)
      }
    }
    }

// send button display controller
useEffect(()=>{
  const audioSend =document.querySelector("#audioSend")
  const questionSend =document.querySelector("#questionSend")
  if(audioSend){
    if(waiting=="waiting"){
      audioSend.style.display="none"
      questionSend.style.display="none"
    }else{
      audioSend.style.display="block"
      questionSend.style.display="block"
    }
  }
})


//  submit question to server and fetch answer 
  const handleSubmit = async (e) => {
    const input = document.getElementById("question");
    e.preventDefault();
    if (question == "") {
      Swal.fire({
        title: "Please Type Your Question",
        confirmButtonColor: "#7F0707",
        confirmButtonText: "Ok",
      });
    } else {
      const options = {
        method: "POST",
        body: JSON.stringify({
          message: question,
          model: model,
          endPoint: endPoint,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        setwaiting("waiting");
        const response = await fetch(process.env.REACT_APP_FETCH_CHAT,options
        );
        const data = await response.json();
        setwaiting("");
        if (endPoint == "https://api.openai.com/v1/completions") {
          setchat([
            ...chat,
            { question: question, answer: data.choices[0].text },
          ]);
        } else if (endPoint == "https://api.openai.com/v1/chat/completions") {
          setchat([
            ...chat,
            { question: question, answer: data.choices[0].message.content },
          ]);
        }
        
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: `Sorry, there's an issue with the server. Please try again later.`,
        confirmButtonColor: "#7F0707",
        confirmButtonText: "Ok",
        
        });
        setwaiting("");
      }
    }
    input.value = "";
    setquestion("");
    if(window.innerHeight < document.body.scrollHeight){
      window.scrollTo(0, document.body.scrollHeight);
    }
    
  };
  window.scrollTo(0, document.body.scrollHeight)

  // set title when save button clicked
  const titler = async (e) => {
    let title =
      e.target.parentElement.parentElement.parentElement.parentElement
        .parentElement.parentElement.children[0].children[1].innerHTML;
    setsaveTitle(
      e.target.parentElement.parentElement.parentElement.parentElement
        .parentElement.parentElement.children[0].children[1].innerText
    );

    await setsaveAnswer(
      e.target.parentElement.parentElement.parentElement.parentElement
        .parentElement.children[1].innerText
    );

    setsaveTitle(title);
  };

  // copy button
  const copy = async (e) => {
    // function unsecuredCopyToClipboard(text) {
    //   const textArea = document.createElement("textarea");
    //   textArea.value = text;
    //   document.body.appendChild(textArea);
    //   textArea.focus();
    //   textArea.select();
    //   try {
    //     document.execCommand('copy');
    //   } catch (err) {
    //     console.error('Unable to copy to clipboard', err);
    //   }
    //   document.body.removeChild(textArea);
    // }
    const copyText =
      e.target.parentElement.parentElement.parentElement.children[1].innerText;

    // copyText && navigator.clipboard.writeText("copyText");
    // alert("Copied the text: " + copyText);
    try {
      navigator.clipboard.writeText(copyText);
      Swal.fire({
        title: "Text Copied",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (err) {
      alert("Failed to copy: ", err);
    }
  };

  // send saved answer data to firebase
  const saveDoc = async () => {
    try {
      const va = saveTitlenew != "" ? saveTitlenew : saveTitle;
      const docRef = await addDoc(collection(db, "saved"), {
        userId: user.uid,
        question: va,
        answer: saveAnswer,
      }).then(()=>{
        Swal.fire({
          title: "Saved",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });

      })
      // console.log("Document written with ID: ", docRef.id);
      
      cancel();
     
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  // cancel button on modal
  const cancel = () => {
    setsaveTitlenew("");
    let titleCheckBox = document.getElementById("titleCheckBox");
    titleCheckBox.checked = false;
    let input = document.getElementById("titleUpdater");
    input.classList.add("d-none");
    input.value = "";
  };

  // control display of recommend title input
  const newtitle = async (e) => {
    let titleCheckBox = document.getElementById("titleCheckBox");
    let input = document.getElementById("titleUpdater");

    input.classList.toggle("d-none");
  };

  //fetch firebase saved items data
  useEffect(() => {
    db.collection("saved").onSnapshot((snapshot) =>
      setsavedChat(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      )
    );
  }, []);

  // delete chat from saved items list in firebase
  const deleteChat = (question) => {
    const docRef = doc(db, "saved", question);
    deleteDoc(docRef);
    document
      .querySelector(".saved__delete__btn__image")
      .classList.remove("d-none");

    Swal.fire({
      title: "Chat Deleted",
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  };

  // delay displaying delete btn image when cancelled
  const deleteCancel = () => {
    setTimeout(() => {
      document
        .querySelector(".saved__delete__btn__image")
        .classList.remove("d-none");
    }, 1000);
  };

  // control displaying of saved items list
  const savedDisplay = (e) => {
    const savedDisplayBtn = document.getElementById("savedDisplayBtn");
    savedDisplayBtn.classList.toggle("back");
    const saved__list__items = document.querySelector(".saved__list__items");
    saved__list__items.classList.toggle("d-none");
    savedDisplayBtn.parentElement.children[1].classList.toggle("d-none");
    savedDisplayBtn.parentElement.children[2].classList.toggle("d-none");
    savedDisplayBtn.parentElement.children[3].classList.toggle("d-none");
  };
  // control toggling for mobile size when clicked to not display the whole list
  const savedItemsToggle = () => {
    const savedDisplayBtn = document.getElementById("savedDisplayBtn");
    savedDisplayBtn.classList.toggle("back");
    const saved__list__items = document.querySelector(".saved__list__items");
    saved__list__items.classList.toggle("d-none");
    savedDisplayBtn.parentElement.children[1].classList.toggle("d-none");
    savedDisplayBtn.parentElement.children[2].classList.toggle("d-none");
    savedDisplayBtn.parentElement.children[3].classList.toggle("d-none");
  };
  // control displaying of transcriptionMode list
  const transcriptionMode = (e) => {
    const transcriptionModeBtn = document.getElementById("transcriptionModeBtn");
    const audioForm = document.getElementById("audioForm");
    const questionForm = document.getElementById("questionForm");
    transcriptionModeBtn.classList.toggle("back");
    transcriptionModeBtn.children[1].classList.toggle("d-none");
    transcriptionModeBtn.children[2].classList.toggle("d-none");
    audioForm.classList.toggle("d-none");
    questionForm.classList.toggle("d-none");
    transcriptionModeBtn.parentElement.children[0].classList.toggle("d-none");
    transcriptionModeBtn.parentElement.children[2].classList.toggle("d-none");
    transcriptionModeBtn.parentElement.children[3].classList.toggle("d-none");
    
    if(model=="whisper-1"){
      setmodel("gpt-3.5-turbo")
    }else{
      setmodel("whisper-1")
    }

  };




  

  // give grey color to selected model
  useEffect(() => {
    const selectedModel = document.getElementById(model);
    if (selectedModel) {
      selectedModel.style.backgroundColor = "gray";
    }
  });

  // change the model when clicked and endpoint
  const changeModel = (newModel, newendPoint, newModelName, e) => {
    e.preventDefault();
    const selectedModel = document.getElementById(model);
    const current = document.getElementById(newModel);
    if (current) {
      current.style.backgroundColor = "yellow";
    }
    if (selectedModel) {
      selectedModel.style.backgroundColor = "";
    }
    setmodel(newModel);
    setmodelName(newModelName);
    setendPoint(newendPoint);
  };

  // colntrol the height of model list
  const modelHeight = () => {
    const modelDrop = document.getElementById("modelDrop");
    modelDrop.style.overflow = "scroll";
  };

  // clear chat from the window
  const clearChat = (e) => {
    e.preventDefault();
    setchat("");
  };

  // hide slider control for mobile size
  const sliderHide = (e) => {
    e.preventDefault();
    let width = window.screen.width;
    if (width < 500) {
      document.querySelector(".temp-slider").classList.toggle("d-none");
      document.querySelector(".temprature_value").classList.toggle("d-none");
    }
  };

  // sign out
  const signout = (e) => {
    signOut(auth)
      .then(() => {
        Navigate("/");
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        // An error happened.
      });
  };
 

  
  

  

  return (
    <div className="light">
      <div className="row g-0 ">
        <div className="left col-lg-3 col-xl-2 g-0 ">
          <div className="menu">
            <img className="menu__logo" src={logo}></img>
            <div className="menu__title fw-bold d-lg-none">ADF GPT</div>
            <div className="menu__items ">
              <div className="menu__item saved__section"
                onClick={(e) => savedDisplay()}
                id="savedDisplayBtn"
              >
                <div className="menu__item__icon" id="savedBack">
                  <AiFillFolder />
                </div>

                <div className="menu__item__text " id="savedBack">
                  Saved
                </div>

                <div className="menu__item__icon hide" id="hide">
                  <IoArrowUndoSharp />
                </div>
                <div
                  className="menu__item__text hide back_to_home"
                  id="hide back_to_home"
                >
                  Back to Home
                </div>
              </div>

              <div className="menu__item whisper__section"
                onClick={(e) => transcriptionMode()}
                id="transcriptionModeBtn"
              >
                
                <div className="menu__item__icon" id="savedBack">
                  <SiAudiomack />
                </div>
                <Tooltip
                      title={
                        <div className="toolTip">
                          Convert speech to text
                        </div>
                      }
                      placement="right-end"
                      enterDelay={1500}
                      enterNextDelay={1500}
                      className="menu__item__text "
                    >
                      <div className="menu__item__text " id="savedBack">
                  Audio Transcription
                </div>
                    </Tooltip>

                

                <div className="menu__item__icon hide" id="hide">
                  <IoArrowUndoSharp />
                </div>
                <div
                  className="menu__item__text hide back_to_home"
                  id="hide back_to_home"
                >
                  Back to Home
                </div>
              </div>
              <div className="menu__item model_row"
                onClick={(e) => modelHeight(e)}
              >
                <div className="dropdown w-100">
                  <button
                    className="btn  px-0 text-white d-flex align-items-center"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="menu__item__icon">
                      <FaCubes />
                    </div>
                    <div className="d-md-flex  justify-content-between w-100">
                      <Tooltip
                      placement="right-end"
                      enterDelay={1500}
                      enterNextDelay={1500}
                        title={
                          <div className="toolTip">
                            The model which will generate the completion. Some
                            models are suitable for natural language tasks,
                            others specialize in code.
                            <a
                              href="https://platform.openai.com/docs/models/overview"
                              target="_blank"
                              className="text-warning"
                            >
                               Learn more
                            </a>{" "}
                            .
                          </div>
                        }
                       
                        className="menu__item__text "
                      >
                        <div className=" dropdown-default d-none d-lg-flex">
                          {" "}
                          Model{" (" + modelName + ")"}
                        </div>
                      </Tooltip>

                      <div className="menu__item__text__drop">
                        <FaAngleDown />
                      </div>
                    </div>
                  </button>

                  <ul className="dropdown-menu " id="modelDrop">
                    {modelList &&
                      modelList.map((model, i) => {
                        return (
                          <li
                            key={i}
                            className="dropdown-item modelClass"
                            id={model.model}
                            onClick={(e) =>
                              changeModel(model.model,model.endPoint,model.name,e)
                            }
                          >
                            {model.name}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              
              <div className="menu__item  p-0 m-0">
                <div className="menu__item__icon">
                  <FaRandom onClick={(e) => sliderHide(e)} />
                </div>
                <div className="w-100 d-flex flex-lg-column flex-column-reverse">
                  <div className="menu__item__text temprature_section d-flex justify-content-between ">
                    <Tooltip
                      title={
                        <div className="toolTip">
                          Controls randomness : Lowering results in less random
                          completions. As the temprature appreaches zero, the
                          model will become deterministic and repetitive.
                        </div>
                      }
                      placement="right-end"
                      enterDelay={1500}
                      enterNextDelay={1500}
                      className="menu__item__text "
                    >
                      <div className="d-none d-lg-block">Temperature</div>
                    </Tooltip>
                    <div className="ps-3 temprature_value d-none d-lg-block">
                      {temprature}
                    </div>
                  </div>

                  <Slider
                    className="menu__item__text temp-slider d-none d-lg-block  pb-0"
                    defaultValue={0.5}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    step={0.1}
                    min={0}
                    max={1}
                    onChange={tempratureVal}
                  />
                </div>
              </div>
              <div className="saved__list__items d-none ">
                {savedChat &&
                  savedChat.map((chat, i) => {
                    if (chat.data.userId == user.uid) {
                      return (
                        <div
                          key={i}
                          className="saved__list  justify-content-between align-items-center"
                          id="savedList"
                        >
                          <div
                            className="saved__title "
                            onClick={(e) => {
                              savedItemsToggle();
                              setchat([
                                {
                                  question: chat.data.question,
                                  answer: chat.data.answer,
                                },
                              ]);
                            }}
                          >
                            {chat.data.question}
                          </div>
                          <div>
                            <button
                              type="button"
                              className="btn saved__delete__btn "
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal2"
                              onClick={(e) => {
                                setdeleteID(chat.id);
                                document
                                  .querySelector(".saved__delete__btn__image")
                                  .classList.add("d-none");
                              }}
                            >
                              <div
                                id="savedListDeleteBtn"
                                className="fs-4  m-0"
                              >
                                <img
                                  className="saved__delete__btn__image"
                                  src={deleteIcon}
                                ></img>
                              </div>
                            </button>

                            <div
                              className="modal fade"
                              id="exampleModal2"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-body text-dark fw-bold">
                                    Are you Sure You want to Delete?
                                  </div>

                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-success"
                                      data-bs-dismiss="modal"
                                      onClick={(e) => deleteCancel()}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      data-bs-dismiss="modal"
                                      onClick={(e) => deleteChat(deleteID)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>

              

              <div className="menu__item menu_account"
                onClick={(e) => signout()}
              >
                <div className="menu__item__icon ">
                  <BiLogOut />
                </div>
                <div className="menu__item__text">Sign Out</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-xl-10 g-0">
          <div className="body">
            <div className="body__btns d-flex justify-content-between">
              <div
                className="body__mode light-mode d-flex align-items-center "
                onClick={lightMode}
              >
                <div className="body__mode__text darkText">Dark </div>
                <div className="body__mode__icon lightModeIcon px-2">
                  <BsFillMoonFill className="darkIcon" />
                  <BsFillSunFill className="lightIcon d-none" />
                </div>
              </div>
            </div>
            <div className="body__chat__body">

            </div>
            <div className="body__chat__wellcome ">
              Hello! How may I assist you today?
            </div>
            <div className="body__chat">
              <div className="body__chat__wellcome body__chat__answer   d-flex ">
                <div className="body__chat__answer__icon d-flex flex-column justify-content-between">
                  <img className="for__dark d-none" src={gptLogo}></img>

                  <img className="for__light d-none" src={gptLogoRed}></img>
                  <div className="d-flex flex-column mb-2 align-items-end"></div>
                </div>
                <div className="body__chat__answer__text">
                  Hello! How may I assist you today?
                </div>
              </div>

              {chat &&
                chat.map((chat, i) => {
                  return (
                    <div key={i}>
                      <div className="body__chat__question d-flex ">
                        <div className="body__chat__question__icon">
                          <img src={adfLogo} />
                        </div>
                        <div className="body__chat__question__text">
                          {chat.question}
                        </div>
                      </div>
                      <div className="body__chat__answer d-flex ">
                        <div className="body__chat__answer__icon d-flex flex-column justify-content-between">
                          <img className="for__dark d-none" src={gptLogo}></img>

                          <img
                            className="for__light d-none"
                            src={gptLogoRed}
                          ></img>

                          <div className="body__chat__answer__mini d-flex flex-column  align-items-end">
                            <div>
                              <button
                                type="button"
                                className="btn p-0"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <img
                                  src={saveIcon}
                                  className="mb-3 saveIconLight "
                                  onClick={(e) => titler(e)}
                                />
                                <img
                                  src={saveIconWhite}
                                  className="mb-3   saveIconDark d-none"
                                  onClick={(e) => titler(e)}
                                />
                              </button>

                              <div
                                className="modal fade"
                                id="exampleModal"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5 className="modal-title fw-bold fs-3">
                                        Title:- {saveTitle}
                                      </h5>
                                    </div>
                                    <div className="modal-body">
                                      <input
                                        type="checkbox"
                                        value="1"
                                        id="titleCheckBox"
                                        onClick={(e) => newtitle()}
                                        className="me-1"
                                      ></input>
                                      <div className="d-inline fw-bold ">
                                        {" "}
                                        Recommend new title
                                      </div>

                                      <br />
                                      <form>
                                        <input
                                          onChange={(e) => {
                                            setsaveTitlenew(e.target.value);
                                          }}
                                          id="titleUpdater"
                                          className="d-none w-100 d-flex overflow-scroll"
                                          defaultValue={saveTitle}
                                        ></input>
                                      </form>
                                    </div>

                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => cancel(e)}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-success"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => saveDoc()}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <img
                              src={copyIcon}
                              className="copyIconLight"
                              onClick={(e) => copy(e)}
                            ></img>
                            <img
                              src={copyIconWhite}
                              className="copyIconDark d-none"
                              onClick={(e) => copy(e)}
                            ></img>
                          </div>
                        </div>
                        <div className="body__chat__answer__text">
                          {chat.answer}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {waiting == "waiting" ? (
                <div className="ps-5 loader-inner ball-pulse">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                ""
              )}
              <div className="chat__bottom__scroll">

              </div>
            </div>




            <div className="body__bottom ">
           
              <div className="d-flex  align-items-center">
                <div className="body__btn clear__btn d-flex  justify-content-center m-2">
                  <div
                    className="body__btn__icon "
                    onClick={(e) => clearChat(e)}
                  >
                    <GiBroom />
                  </div>
                </div>
                
            <form className="body__bottom__input d-flex d-none justify-content-between align-items-center" id="audioForm" onSubmit={transcribe}>
              <div>
            <label htmlFor="audio" className="audio__label ">
        <SiAudiomack className="me-2"/>{file==null?"Select audio File":"Change Audio File"}
    </label>
      <input type="file" id="audio" className="d-none" onChange={handleFileInputChange} />
      <div className="audio__file__name d-inline">
      {file && file.name}
      </div>
      </div>
      <button
                    className="body__bottom__input__icon audioSend  m-2  " id="audioSend"
                    type="submit"
                  >
                    <IoMdSend />
                  </button>
    </form>


                <form
                  className="body__bottom__input d-flex "
                  id="questionForm" 
                  onSubmit={(e) => handleSubmit(e)}           
                >                
                  <textarea
                    className="body__bottom__input__text  "
                    placeholder="Type Question . . ."
                    id="question"
                    name="question"
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => setquestion(e.target.value)}
                    rows={1}
                  ></textarea>
                  <button
                    className="body__bottom__input__icon  m-2 "
                    type="submit" id="questionSend"
                  >
                    <IoMdSend />
                  </button>
                </form>
              </div>
              <div className="body__bottom__text d-none d-lg-flex justify-content-center text-capitalize ">
                Powerd By indepenedt news & media p.l.c
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
