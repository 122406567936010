import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { auth, db } from "./firebase";
import { useEffect, useState } from 'react';
import LogIn from './Components/LogIn';
import Reset from './Components/Reset';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import { useStateValue } from './Components/StateProvider/StateProvider';
import { db,auth } from './firebase';
import { onAuthStateChanged  } from "firebase/auth";

function App() {
  const [{ basket, user }, dispatch] = useStateValue();
  const[currentuser,setcurrentuser]=useState(null);
  
  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          const email = user.email;
          setcurrentuser(email)
        } else {
            setcurrentuser("SignIN")
        }
      });
    
})
  // const [user,setuser]=useState("")
      // user email query from firebase
      useEffect(() => {
        auth.onAuthStateChanged((authUser) => {
          // console.log("THE USER IS >>> ", authUser);
          if (authUser) {
            // the user just logged in / the user was logged in
    
            dispatch({
              type: "SET_USER",
              user: authUser,
              
            });
          } else {
            // the user is logged out
            dispatch({
              type: "SET_USER",
              user: null,
            });
          }
        });
      }, []);

const page=()=>{
  if  (currentuser=="SignIN"){
    return <LogIn/>
  }else if(user==null){
    return 
  }else if(user){
    return <Home />
  }
}


  return (
    <div className="gpt">

<BrowserRouter>



<Routes>
<Route path="/" element={page()}></Route>
<Route path="/reset" element={<Reset/>}></Route>



</Routes>
</BrowserRouter>
    
      
    </div>
  );
}

export default App;
